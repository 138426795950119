<template>
  <div class="login">
    <div class="login_title">
      <h3>登录</h3>
    </div>
    <div class="login_from">
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="username"
          label="账号"
          placeholder="登录手机号"
          :rules="[{ required: true, message: '请输入登录手机号' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="password"
          label="密码"
          placeholder="登录密码"
          :rules="[{ required: true, message: '请输入登录密码' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="danger" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <div class="reglogin">
        <van-cell title="">
          <template #right-icon>
            <van-button @click="setpwd" size="small">找回密码</van-button>
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      username: "", // 账号
      password: "", // 密码
    }
  },
  methods: {
    onSubmit() {
      console.log("submit");
      let that = null;
      that = this;
      that.$axios
        .post(
          that.$store.state.domain + "team/user/login",
          that.$qs.stringify({
            username: that.username,
            password: that.password
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            if (res.data.data) {
              localStorage.setItem("SHOP_UID", res.data.data);
              localStorage.setItem("SHOP_LOGIN", true);
            }
            that.$toast(res.data.msg ? res.data.msg : "登陆成功");
            that.$router.replace({
              name: "Home",
              query: {
                t: new Date().getTime()
              }
            })
          }else {
            that.$toast(res.data.msg ? res.data.msg : "密码不正确");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 找回密码页面
    setpwd() {
      this.$router.push({
        name: "Setpwd",
        query: {
          t: new Date().getTime()
        }
      })
    }
  },
}
</script>
<style lang="less">
.login {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .login_title {
    margin: 20% 0;
  }
  .login_from {
    margin: 10px;
    .van-cell {
      .van-cell__title {
        width: 50px;
      }
      .van-cell__value {
        flex: 1;
      }
    }
    .reglogin {
      .van-cell {
        padding: 10px 16px;
        .van-cell__title {
          flex: 1;
        }
        .van-button {
          border-color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}
</style>